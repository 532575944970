import validate from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.4.0__ioredis@5.4.1__4uwd6sb3olpclgbzhel5dnzpxq/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authenticated_45global from "D:/dev_web/middleware/01.authenticated.global.ts";
import _02_45sitedata_45global from "D:/dev_web/middleware/02.sitedata.global.ts";
import manifest_45route_45rule from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.4.0__ioredis@5.4.1__4uwd6sb3olpclgbzhel5dnzpxq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authenticated_45global,
  _02_45sitedata_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("D:/dev_web/middleware/auth.ts"),
  hidewhenauth: () => import("D:/dev_web/middleware/hidewhenauth.ts")
}