import { default as _91_46_46_46slug_93HOHVAzhyYAMeta } from "D:/dev_web/pages/[...slug].vue?macro=true";
import { default as account_45editjvpVNbebCKMeta } from "D:/dev_web/pages/account-edit.vue?macro=true";
import { default as accountrmBvQZXhysMeta } from "D:/dev_web/pages/account.vue?macro=true";
import { default as cartKRtLWkbkHBMeta } from "D:/dev_web/pages/cart.vue?macro=true";
import { default as change_45passwordzdLr4ymAcvMeta } from "D:/dev_web/pages/change-password.vue?macro=true";
import { default as checkout_45doneiCfO958s02Meta } from "D:/dev_web/pages/checkout-done.vue?macro=true";
import { default as checkout_45paypal_45donebm9xRPoTHVMeta } from "D:/dev_web/pages/checkout-paypal-done.vue?macro=true";
import { default as checkout_45paypalE2Iyehq7MoMeta } from "D:/dev_web/pages/checkout-paypal.vue?macro=true";
import { default as checkoutfQoLXMNsolMeta } from "D:/dev_web/pages/checkout.vue?macro=true";
import { default as forgot_45password4pJuAa2ssFMeta } from "D:/dev_web/pages/forgot-password.vue?macro=true";
import { default as indexhdP9oJyh1NMeta } from "D:/dev_web/pages/index.vue?macro=true";
import { default as login_45qrcodedi0HwdXj5ZMeta } from "D:/dev_web/pages/login-qrcode.vue?macro=true";
import { default as loginoGUTr01GGmMeta } from "D:/dev_web/pages/login.vue?macro=true";
import { default as order_45checkoutt7aFP6dU6OMeta } from "D:/dev_web/pages/order-checkout.vue?macro=true";
import { default as _91id_93aS0Ckj0sFiMeta } from "D:/dev_web/pages/order-qrcode/[id].vue?macro=true";
import { default as order_45resultpABTjhdqjGMeta } from "D:/dev_web/pages/order-result.vue?macro=true";
import { default as _91id_939MDWzTqNpfMeta } from "D:/dev_web/pages/order/[id].vue?macro=true";
import { default as ordersQK4Azw2LEfMeta } from "D:/dev_web/pages/orders.vue?macro=true";
import { default as recharge_45logXXuBSCDOiWMeta } from "D:/dev_web/pages/recharge-log.vue?macro=true";
import { default as rechargeUMHkldNVaJMeta } from "D:/dev_web/pages/recharge.vue?macro=true";
import { default as register0VfGnzk9qVMeta } from "D:/dev_web/pages/register.vue?macro=true";
import { default as reset_45passwordOUzA4hn5xlMeta } from "D:/dev_web/pages/reset-password.vue?macro=true";
import { default as _91_46_46_46slug_93x9JDj0h6hSMeta } from "D:/dev_web/pages/shop/[...slug].vue?macro=true";
import { default as transfer_45logi0KJubykB0Meta } from "D:/dev_web/pages/transfer-log.vue?macro=true";
import { default as transfer3PtB9qZVgGMeta } from "D:/dev_web/pages/transfer.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "slug___vi___default",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "slug___vi",
    path: "/vi/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "account-edit___en",
    path: "/en/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account-edit___vi___default",
    path: "/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account-edit___vi",
    path: "/vi/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "account___vi___default",
    path: "/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "account___vi",
    path: "/vi/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "cart___vi___default",
    path: "/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "cart___vi",
    path: "/vi/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "change-password___vi___default",
    path: "/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "change-password___vi",
    path: "/vi/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "checkout-done___en",
    path: "/en/checkout-done",
    meta: checkout_45doneiCfO958s02Meta || {},
    component: () => import("D:/dev_web/pages/checkout-done.vue")
  },
  {
    name: "checkout-done___vi___default",
    path: "/checkout-done",
    meta: checkout_45doneiCfO958s02Meta || {},
    component: () => import("D:/dev_web/pages/checkout-done.vue")
  },
  {
    name: "checkout-done___vi",
    path: "/vi/checkout-done",
    meta: checkout_45doneiCfO958s02Meta || {},
    component: () => import("D:/dev_web/pages/checkout-done.vue")
  },
  {
    name: "checkout-paypal-done___en",
    path: "/en/checkout-paypal-done",
    meta: checkout_45paypal_45donebm9xRPoTHVMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-done.vue")
  },
  {
    name: "checkout-paypal-done___vi___default",
    path: "/checkout-paypal-done",
    meta: checkout_45paypal_45donebm9xRPoTHVMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-done.vue")
  },
  {
    name: "checkout-paypal-done___vi",
    path: "/vi/checkout-paypal-done",
    meta: checkout_45paypal_45donebm9xRPoTHVMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-done.vue")
  },
  {
    name: "checkout-paypal___en",
    path: "/en/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-paypal___vi___default",
    path: "/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-paypal___vi",
    path: "/vi/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "checkout___vi___default",
    path: "/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "checkout___vi",
    path: "/vi/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___vi___default",
    path: "/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___vi",
    path: "/vi/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "index___vi___default",
    path: "/",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "index___vi",
    path: "/vi",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "login-qrcode___en",
    path: "/en/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login-qrcode___vi___default",
    path: "/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login-qrcode___vi",
    path: "/vi/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "login___vi___default",
    path: "/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "login___vi",
    path: "/vi/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "order-checkout___en",
    path: "/en/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-checkout___vi___default",
    path: "/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-checkout___vi",
    path: "/vi/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-qrcode-id___en",
    path: "/en/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-qrcode-id___vi___default",
    path: "/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-qrcode-id___vi",
    path: "/vi/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-result___en",
    path: "/en/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-result___vi___default",
    path: "/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-result___vi",
    path: "/vi/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "order-id___vi___default",
    path: "/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "order-id___vi",
    path: "/vi/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "orders___vi___default",
    path: "/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "orders___vi",
    path: "/vi/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "recharge-log___en",
    path: "/en/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge-log___vi___default",
    path: "/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge-log___vi",
    path: "/vi/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge___en",
    path: "/en/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "recharge___vi___default",
    path: "/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "recharge___vi",
    path: "/vi/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "register___vi___default",
    path: "/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "register___vi",
    path: "/vi/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "reset-password___vi___default",
    path: "/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "reset-password___vi",
    path: "/vi/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "shop-slug___en",
    path: "/en/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___vi___default",
    path: "/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___vi",
    path: "/vi/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "transfer-log___en",
    path: "/en/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer-log___vi___default",
    path: "/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer-log___vi",
    path: "/vi/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer___en",
    path: "/en/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  },
  {
    name: "transfer___vi___default",
    path: "/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  },
  {
    name: "transfer___vi",
    path: "/vi/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  }
]